<template>
  <div class="content-wrapper">

    <SucessoModal
      itemIdentifier="Agendamento"
      :modalShow="modalSuccess.showModal"
      :typeModal="modalSuccess.typeModal"
      :caseModal="modalSuccess.caseModal"
      @confirmed="redirectToList"
      @canceled="closeModal"
    />

    <NewAddressModal
      :showModal="modalNewAddress.showModal"
      @confirmed="addNewAddress"
      @canceled="closeModalNewAddress"
    />

    <ConfirmModal
      itemIdentifier="Agendamento"
      :modalShow="modalCancel.showModal"
      :typeModal="modalCancel.typeModal"
      @confirmed="redirectToList"
      @canceled="closeModalCancel"
    />

    <ConfirmCampanhaNaoVigenteModal
        :modal-show='showModalCampanhaNaoVigente'
        @confirmed="() => confirmedCampanhaNaoVigente(saveAgendamento)"
        @canceled="canceledCampanhaNaoVigente"
    />

    <SelectEmailsModal
        :modal-show="showModalSelectEmails"
        :users="recipients"
        :defaultCheckedEmails="defaultCheckedEmails"
        @confirmed="confirmSelectEmails"
        @closeModal="closeModalSelectEmails"
    />

    <PageHeader screenName="Agendamento" :linkItems="linkItems" />

    <main class="card hidden_overflow_tabela pt-2 px-1 pb-2">
      <validation-observer ref="agendamentoForm">
      <b-form>
        <b-overlay :show="loading" rounded="lg" opacity="0.6" class="w-100 mt-1">
        <b-row class="pt-2 px-2">
          <b-col lg="4" md="4" sm="12">
            <b-form-group 
              label="CNPJ*" 
              label-for="cnpj" 
              label-class="font_size_label"
            >
              <validation-provider 
                #default="{ errors }" 
                name="cnpj" 
                rules="required|length:18"
              >
                <b-form-input
                  id="cnpj" 
                  v-model="form.cnpj"
                  class="form-control" 
                  placeholder="00.000.000/0000-00"
                  name="cnpj"
                  v-mask=" '##.###.###/####-##'" 
                  @blur.native="findCnpjCompany"
                />
                <small v-if="errors[0]" class="text-danger">
                  {{errors[0]}}
                </small>
  
              </validation-provider>
            </b-form-group>
  
          </b-col>
          <b-col lg="4" md="4" sm="12">
            <b-form-group 
              label="Nome Fantasia*" 
              label-for="nome_fantasia" 
              label-class="font_size_label"
            >
              <validation-provider 
                #default="{ errors }" 
                name="Nome fantasia" 
                rules="required"
              >
                <b-form-input
                  id="nome_fantasia" 
                  v-model="form.nome_fantasia"
                  class="form-control" 
                  name="nome_fantasia"
                  disabled
                />
                <small v-if="errors[0]" class="text-danger">
                  {{errors[0]}}
                </small>
  
              </validation-provider>
            </b-form-group>
          </b-col>
  
          <b-col lg="4" md="4" sm="12">
            <b-form-group 
              label="Razão Social*" 
              label-for="razao_social" 
              label-class="font_size_label"
            >
              <validation-provider 
                #default="{ errors }" 
                name="Razão Social" 
                rules="required"
              >
                <b-form-input
                  id="razao_social" 
                  v-model="form.razao_social"
                  class="form-control" 
                  name="razao_social"
                  disabled
                />
                <small v-if="errors[0]" class="text-danger">
                  {{errors[0]}}
                </small>
  
              </validation-provider>
            </b-form-group>
  
          </b-col>  
        </b-row>

        <b-row class="pt-1 px-2">
          <b-col lg="4" md="4" sm="12">
            <b-form-group 
              label="Endereço*" 
              label-for="endereco" 
              label-class="font_size_label"
            >
            <validation-provider 
                #default="{ errors }" 
                name="id_empresa_endereco" 
                rules="required"
            >
              <v-select
                  id="endereco"
                  :options="enderecoOptions"
                  v-model="form.endereco"
                  placeholder="Selecione uma opção"
                  label="descricao"
                  :clearable="false"
                  @input="handleEnderecoSelecionado"
              >
                <template #option="data" >
                  <span
                    :class="{
                      'text-danger font-weight-bold': data.descricao_identificador === 'Outro'
                    }"
                  >
                    <b>{{ data.descricao_identificador }}</b><br/>{{ data.descricao }}</span>
                </template>
                <template #selected-option="data">
                  <span><b>{{ data.descricao_identificador }}</b><br/>{{ data.descricao }}</span>
                </template>
                <span slot="no-options">Nenhuma opção selecionável.</span>
              </v-select>
              <small v-if="form.errorApi.id_empresa_endereco" class="text-danger">
                  {{ form.errorApi.id_empresa_endereco }}
              </small>
            </validation-provider>
            </b-form-group>

          </b-col>
          <b-col lg="4" md="4" sm="12">
            <b-form-group 
              label="Unidade Operacional*" 
              label-for="Unidade Operacional" 
              label-class="font_size_label"
            >
            <validation-provider 
                #default="{ errors }" 
                name="unidade operacional" 
                rules="required"
            >
              <v-select
                  id="unidade_operacional"
                  :options="unidadeOperacionalOptions"
                  v-model="form.unidade_operacional"
                  placeholder="Selecione uma opção"
                  label="descricao"
                  item-text="unidade operacional"
                  item-value="unidade_operacional"
                  :clearable="false"
              >
                <span slot="no-options">Nenhuma opção selecionável.</span>
              </v-select>
              <small v-if="form.errorApi.id_unidade" class="text-danger">
                  {{ form.errorApi.id_unidade }}
              </small>
            </validation-provider>
            </b-form-group>

          </b-col>

        </b-row>

        <b-row class="pt-1 px-2">
          <b-col lg="2" md="4" sm="12">
              <b-form-group label="Data de início*" label-for="data_inicio" label-class="font_size_label">
                <validation-provider #default="{ errors }" name="data de início"
                :rules="{ required: true, date_format: true }">
                  <b-input-group>
                    <b-form-input class="form-control" id="data_inicio" v-mask="'##/##/####'"
                      v-model="form.data_aplicacao" placeholder="00/00/0000" autocomplete="off"
                      ref="dataAplicacao"/>
                    <b-input-group-append>
                      <b-form-datepicker class="form-control d-flex align-items-center" locale="pt-BR"
                        v-model="data_aplicacao" button-only right :hide-header="true"
                        label-help="Selecione a data de início" button-variant="custom-blue" clearable 
                        menu-class="custom-datepicker-menu"/>
                    </b-input-group-append>
                  </b-input-group>
                  <small v-if="errors[0]" class="text-danger">
                  {{errors[0]}}
                </small>
                <small v-if="form.errorApi.data_aplicacao" class="text-danger">
                  {{ form.errorApi.data_aplicacao }}
                </small>
                </validation-provider>
              </b-form-group>
            </b-col>

          <b-col lg="2" md="4" sm="12">
              <b-form-group label="Data de término*" label-for="data_termino" label-class="font_size_label">
                <validation-provider #default="{ errors }" name="data de término"
                :rules="{ required: true, date_format: true, after_start_date: form.data_aplicacao }">
                  <b-input-group>
                    <b-form-input class="form-control" id="data_termino" v-mask="'##/##/####'"
                      v-model="form.data_final_aplicacao" placeholder="00/00/0000" autocomplete="off" 
                      ref="dataTermino"/>
                    <b-input-group-append>
                      <b-form-datepicker class="form-control d-flex align-items-center" locale="pt-BR"
                        v-model="data_final_aplicacao" button-only right :hide-header="true"
                        label-help="Selecione a data de término" button-variant="custom-blue" clearable
                        menu-class="custom-datepicker-menu"/>
                    </b-input-group-append>
                  </b-input-group>
                  <small v-if="errors[0]" class="text-danger">
                  {{errors[0]}}
                </small>
                <small v-if="form.errorApi.data_final_aplicacao" class="text-danger">
                  {{ form.errorApi.data_final_aplicacao }}
                </small>
                </validation-provider>
              </b-form-group>
            </b-col>

          <b-col lg="2" md="2" sm="12">
            <b-form-group 
              label="Horário de início" 
              label-for="horario_inicio" 
              label-class="font_size_label"
            >
              <validation-provider #default="{ errors }" name="horário de início" :rules="horarioValido"
              :custom-messages="{
                regex: 'O campo horário de início deve estar no formato HH:MM'
              }">
                <b-input-group>
                  <b-form-input 
                    class="form-control" 
                    id="horario_inicio" 
                    v-mask="'##:##'" 
                    v-model="form.horario_inicio" 
                    placeholder="00:00" 
                    autocomplete="off"
                  />
                  <b-input-group-append>
                    <b-form-timepicker 
                      class="form-control d-flex align-items-center" 
                      locale="pt-BR"
                      no-close-button 
                      v-model="form.horario_inicio" 
                      button-only 
                      right 
                      :hide-header="true" 
                      label-help="Selecione o horário de início" 
                      button-variant="custom-blue" 
                      clearable
                    />
                  </b-input-group-append>
                </b-input-group>
                <small v-if="errors[0]" class="text-danger">
                  {{ errors[0] }}
                </small>
                <small v-if="form.errorApi.horario_inicio" class="text-danger">
                  {{ form.errorApi.horario_inicio }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col lg="2" md="2" sm="12">
            <b-form-group label="Horário de término" label-for="horario_termino" label-class="font_size_label">
              <validation-provider #default="{ errors }" name="horário de término" :rules="horarioValido"
              :custom-messages="{
                regex: 'O campo horário de término deve estar no formato HH:MM'
              }">
                <b-input-group>
                  <b-form-input 
                    class="form-control" 
                    id="horario_termino" 
                    v-mask="'##:##'" 
                    v-model="form.horario_termino" 
                    placeholder="00:00" 
                    autocomplete="off"
                  />
                  <b-input-group-append>
                    <b-form-timepicker 
                      class="form-control d-flex align-items-center" 
                      locale="pt-BR"
                      no-close-button 
                      v-model="form.horario_termino" 
                      button-only 
                      right 
                      :hide-header="true" 
                      label-help="Selecione o horário de término" 
                      button-variant="custom-blue" 
                      clearable
                    />
                  </b-input-group-append>
                </b-input-group>
                <small v-if="errors[0]" class="text-danger">
                  {{ errors[0] }}
                </small>
                <small v-if="form.errorApi.horario_termino" class="text-danger">
                  {{ form.errorApi.horario_termino }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col lg="4" md="3" sm="12">
            <b-form-group 
              label="Período" 
              label-for="periodo" 
              label-class="font_size_label"
            >
              <validation-provider 
                #default="{ errors }" 
                name="período"
              >
                <v-select
                  id="periodo"
                  :options="periodoOptions"
                  v-model="form.periodo"
                  placeholder="Selecione um período"
                  multiple
                  label="descricao"
                  item-text="periodo"
                  item-value="periodo"
                  :clearable="false"
              >
                <span slot="no-options">
                  Nenhuma opção selecionável.
              </span>
              </v-select>
                <small v-if="errors[0]" class="text-danger">
                  {{ errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>

        <b-row class="pt-1 px-2">
          <b-col lg="4" md="4" sm="12">
            <b-form-group 
              label="Selecione uma campanha*" 
              label-for="campanha" 
              label-class="font_size_label"
            >
            <validation-provider 
                #default="{ errors }" 
                name="campanha" 
                rules="required"
            >
              <v-select
                  id="campanha"
                  :options="campaignsOptions"
                  v-model="form.campaign"
                  placeholder="Selecione a campanha"
                  label="descricao"
                  item-text="campanha"
                  item-value="campanha"
                  :clearable="false"
                  @input="setDosesPrevistas"
                  :disabled="campanhaDisabled"
              >
                <span slot="no-options">Nenhuma opção selecionável.</span>
              </v-select>
              <small v-if="form.errorApi.id_campanha" class="text-danger">
                O campo campanha é obrigatório
              </small>
            </validation-provider>
            </b-form-group>
          </b-col>

          <b-col lg="2" md="2" sm="12">
            <b-form-group 
              label="Doses previstas*" 
              label-for="doses_previstas" 
              label-class="font_size_label"
            >
            <validation-provider 
              #default="{ errors }" 
              name="doses previstas"
              rules="required|max:9"
            >
            <b-form-input
              id="doses_previstas" 
              v-model="form.doses_previstas"
              class="form-control" 
              name="doses_previstas"
              v-mask="'#########'"
              placeholder="--"
            />
            <small v-if="errors[0]" class="text-danger">
              {{ errors[0] }}
            </small>
            </validation-provider>
            </b-form-group>
          </b-col>

          <b-col lg="2" md="2" sm="12">
            <b-form-group 
              label="Doses aplicadas" 
              label-for="doses_aplicadas" 
              label-class="font_size_label"
              v-b-tooltip.hover="{title: 'O número de doses aplicadas será atualizado conforme os colaboradores forem vacinados', customClass: 'custom-tooltip'}"
            >
              <b-form-input
                id="doses_aplicadas" 
                v-model="form.doses_aplicadas"
                class="form-control" 
                name="doses_aplicadas"
                placeholder="--"
                :disabled="true"
              />
            </b-form-group>

          </b-col>

          <b-col lg="4" md="4" sm="12">
            <b-form-group 
              label="Status" 
              label-for="status" 
              label-class="font_size_label"
            >
            <validation-provider 
                #default="{ errors }" 
                name="status" 
                rules="required"
            >
              <v-select
                  id="status"
                  :options="statusOptions"
                  v-model="form.status"
                  placeholder="Selecione o status"
                  label="status"
                  item-text="Status"
                  item-value="status"
                  :clearable="false"
              />
              <small v-if="errors[0]" class="text-danger">
                  {{errors[0]}}
              </small>
            </validation-provider>
            </b-form-group>

          </b-col>

        </b-row>


        <b-row class="pt-1 px-2">
          <b-col lg="12" md="12" sm="12">
            <b-form-group 
              label="Observações" 
              label-for="observacoes" 
              label-class="font_size_label"
            >
            <validation-provider 
                #default="{ errors }" 
                name="ponto de atendimento" 
                rules="max:500"
            >
              <b-form-textarea
                id="observacoes"
                v-model="form.observacoes"
                placeholder="Ex: Agendamento realizado em função de ..."
                rows="5"
                :state="form.observacoes.length > 500 ? false : null"
              />
              <small v-if="errors[0]" class="text-danger">
                  {{errors[0]}}
              </small>
            </validation-provider>
            </b-form-group>

          </b-col>
        </b-row>
        </b-overlay>
        <b-row class="pt-1 px-2">
              <b-col>
                <b-button 
                  id="save_agendamento"
                  class="mr-2"
                  variant="custom-blue"
                  @click.prevent="saveAgendamento"
                  :disabled="isDisabled"
                >
                <feather-icon icon="CheckIcon" class="mr-50"/>
                <span class="align-middle">Salvar alterações</span>
                <span v-if="loading" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                </b-button>
                <b-button 
                  id="cancel-register-departamento"
                  :disabled="loading"
                  variant="outline-danger"
                  @click.prevent="openModalCancel"
                >
                  <feather-icon icon="XIcon" class="mr-50"/>
                  <span class="align-middle">Cancelar</span>
                </b-button>
              </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    </main>
  </div>
  
</template>

<script>
import { BForm, BCol, BRow, BFormGroup, BFormInput, BButton, BTable, BSpinner,BFormDatepicker,BFormTextarea,BOverlay,BFormTimepicker, BInputGroup,
  BInputGroupAppend } from 'bootstrap-vue'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import { ValidationProvider,ValidationObserver, extend } from "vee-validate"
import VueCleave from 'vue-cleave-component'
import { required } from '@validations'
import vSelect from "vue-select"
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue'
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue'
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { actions, subjects } from "@/libs/acl/rules";
import handleErrors from "@/views/custom-pages/agendamento/services/handleErrors"
import PeriodAgendamentoService from "@/views/custom-pages/agendamento/services/PeriodAgendamentoService"
import ConfirmCampanhaNaoVigenteModal from "@/views/custom-pages/agendamento/components/ConfirmCampanhaNaoVigenteModal.vue"
import NewAddressModal from './components/NewAddressModal.vue'
import { CampanhaNaoVigenteMixin } from "@/views/custom-pages/agendamento/mixins/campanhaNaoVigenteMixin"
import SelectEmailsModal from "@/views/custom-pages/agendamento/components/SelectEmailsModal.vue";
import moment from "moment";
import {
  confirmOkActionTriangleWarn
} from '@/libs/sweetalerts'
import { v4 as uuidv4 } from 'uuid';

extend("date_format", {
  message: "Informe uma data válida",
  validate: (value) => {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    if (!regex.test(value)) return false;

    const [day, month, year] = value.split("/").map(Number);
    const date = new Date(year, month - 1, day);

    return (
      date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day
    );
  }
});

extend("after_start_date", {
  params: ["startDate"],
  message: "A data de término deve ser maior ou igual a data de início",
  validate: (endDate, { startDate }) => {
    if (!startDate || !endDate) return true;

    const parseDate = (dateStr) => {
      const [day, month, year] = dateStr.split("/").map(Number);
      return new Date(year, month - 1, day);
    };

    const start = parseDate(startDate);
    const end = parseDate(endDate);

    return end >= start;
  }
});

export default {
  title: 'Agendamentos',
  mixins: [CampanhaNaoVigenteMixin],
  components: {
    SelectEmailsModal,
    BForm, BCol, BRow, BFormGroup, BFormInput,
    BButton, BTable, CustomPagination, PageHeader,
    ValidationProvider,VueCleave,BFormDatepicker, vSelect,BFormTextarea,
    ValidationObserver, SucessoModal, ConfirmModal,BSpinner,BOverlay, BFormTimepicker,
    ConfirmCampanhaNaoVigenteModal, NewAddressModal, BInputGroup, BInputGroupAppend
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Agendamentos',
          routeName: 'agendamento-list',
        },
        {
          name: 'Cadastrar novo',
          active: true
        }
      ],
      form: {
        campaign: "",
        cnpj: "",
        nome_fantasia: "",
        razao_social: "",
        data_aplicacao: "",
        data_final_aplicacao: "",
        horario_inicio: "",
        horario_termino: "",
        periodo: "",
        doses_previstas: "",
        doses_aplicadas: "",
        status: "Agendado",
        unidade_operacional: "",
        endereco: "",
        observacoes: "",
        errorApi: {
          "data_aplicacao" : "",
          "data_final_aplicacao" : "",
          "horario_inicio" : "",
          "horario_termino" : "",
          "id_campanha" : "",
          "id_unidade" : "",
          "id_empresa" : ""
        }
      },
      data_aplicacao: "",
      data_final_aplicacao: "",
      id_empresa: "",
      statusOptions: ['Agendado', 'Finalizado', 'Cancelado'],
      periodoOptions: [
        {
          descricao: 'Matutino',
          id: 1
        },
        {
          descricao: 'Vespertino',
          id: 2
      
        },
        {
          descricao: 'Noturno',
          id: 3
        }
      ],
      campaignsOptions: [],
      enderecoOptions: [],
      unidadeOperacionalOptions: [],
      required,
      salvando: false,
      loading: false,
      campanhaDisabled: true,
      modalSuccess: {
        showModal: false,
        typeModal: 'success',
        caseModal: 'criacao'
      },
      modalCancel: {
        showModal: false,
        typeModal: 'danger'
      },
      modalNewAddress: {
        showModal: false,
        typeModal: 'success',
      },
      numeroMask: createNumberMask({
        prefix: "",
        includeThousandsSeparator: true,
        allowNegative: false,
        thousandsSeparatorSymbol: ".",
      }),
      recipients: [],
      showModalSelectEmails: false,
      emailsSelecteds: [],
      modalSelectEmailsConfirmed: false,
      defaultCheckedEmails: true
    }
  },

  async mounted() {
    if (!this.verifyPermission()) {
      this.$router.push({name: 'misc-not-authorized'})
    }
  },
  computed: {
    isDisabled() {
      return (
        this.form.errorApi.horario_termino ||
        this.form.cnpj === '' ||
        this.form.nome_fantasia === '' ||
        this.form.razao_social === '' ||
        this.form.data_aplicacao === '' ||
        moment(this.form.data_aplicacao, "DD/MM/YYYY", true).isValid() === false ||
        this.form.data_final_aplicacao === '' ||
        moment(this.form.data_final_aplicacao, "DD/MM/YYYY", true).isValid() === false ||
        moment(this.form.data_final_aplicacao, "DD/MM/YYYY", true).isBefore(moment(this.form.data_aplicacao, "DD/MM/YYYY", true)) ||
        (this.form.horario_inicio && !this.horarioValido.regex.test(this.form.horario_inicio)) ||
        (this.form.horario_termino && !this.horarioValido.regex.test(this.form.horario_termino)) ||
        this.form.endereco === '' ||
        this.form.campaign === '' ||
        this.form.unidade_operacional === '' ||
        this.form.doses_previstas === ''
      );
    },
    horarioValido() {
      return {
        regex: /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/,
      };
    }
  },
  methods: {
    async getRecipients(idCompany, idAddress, idUnity) {
      const params = {
        idEmpresa: idCompany,
        idEmpresaEndereco: idAddress,
        idUnidade: idUnity
      };

      return await this.$http.get(this.$api.agendamentoDestinatarios(), {params: params}).then(({data}) => {
        return data
      });
    },
    async saveAgendamento() {
      this.loading = true

      if (!this.verificaSeCampanhaEhVigenteOuConfirmada()) {
          return
      }

      if (!this.modalSelectEmailsConfirmed) {
        const recipients = await this.getRecipients(this.id_empresa, this.form.endereco.id_empresa_endereco, this.form.unidade_operacional.id)

        this.recipients = recipients.map(recipient => ({
          name: recipient.nome,
          email: recipient.email,
          perfil: recipient.funcao,
          userId: uuidv4()
        }));

        this.showModalSelectEmails = true;
        return
      }

      const formValidated = await this.validateForm()
      const payload = this.payload()

      if(formValidated) {
        await this.$http.post(this.$api.salvarAgendamento(), payload).then(() => {
          handleErrors.cleanErrors(this.form.errorApi)
          this.openModalSuccess()
        }).catch(({response}) => {
          if(response.status != 422) {
            this.handleError(response)
          }
          handleErrors.handleErrorsAgendamento(response.data, this.form.errorApi)
        })
      }
      this.loading = false
    },
    async findCnpjCompany() {
      this.loading = true
      const cnpj = this.form.cnpj.replace(/(\.|\/|\-)/g,"")
      const payload = {
        "cnpj" : cnpj, 
        "api_externa" : false
      }
      await this.$http.post( this.$api.getCompanyByCnpjWithExternalApi(), payload)
        .then(({ data }) => {
          this.form.nome_fantasia = data.nome_fantasia
          this.form.razao_social = data.razao_social
          this.id_empresa = data.id_empresa
          this.getCampaignsByCompany(data.id_empresa)
          this.setAddress(data.empresa_endereco)
        }).catch(data => {
          if(data.response.status == 400) {
            this.handleError(data.response)
            this.clearFields()
          } 
          if(data.response.status == 422) {
            this.clearFields()
          }
        })
        this.loading = false
        
      },
    async getUnits() {
      this.unidadeOperacionalOptions = []
      this.form.unidade_operacional = ""
      await this.$http.get(this.$api.getUnidadePorIdEmpesaEndereco(this.form.endereco.id_empresa_endereco)).then(({ data }) => {
        this.unidadeOperacionalOptions.push({
            id: data.unidade.id_unidade,
            descricao: data.unidade.descricao
        })

        this.form.unidade_operacional = {
          id: data.unidade.id_unidade,
          descricao: data.unidade.descricao
        }

        if (this.form.campaign.id){
          this.setDosesPrevistas();
        }

      })
    },
    async getUnitsByIdCidade() {
      this.unidadeOperacionalOptions = [];
      this.form.unidade_operacional = "";

      try {
        const response = await this.$http.get(this.$api.getUnidadePorIdCidade(this.form.endereco.cidade.id_cidade));
        const { data } = response;

        if (Array.isArray(data) && data.length > 0) {
          this.unidadeOperacionalOptions = data.map(unidade => ({
            id: unidade.id_unidade,
            descricao: unidade.descricao
          }));

          this.form.unidade_operacional = {
            id: data[0].id_unidade,
            descricao: data[0].descricao
          };

          if(this.form.campaign.id){
            this.setDosesPrevistas();
          }
          
        } else {
          this.$toast.error("Nenhuma unidade operacional encontrada para esta cidade.");
        }
      } catch (error) {
        console.error("Erro ao buscar unidades:", error);
        this.$toast.error("Erro ao carregar unidades operacionais.");
      }
    },
    handleEnderecoSelecionado(endereco) {
      switch(endereco.descricao_identificador) {
        case 'Outro':
          this.openModalNewAddress();
          break;
        case 'Novo endereço':
          this.getUnitsByIdCidade();
          break;
        default:
          this.getUnits();
          break;
      }
    },
    getCampaignsByCompany(idCompany) {
      const parameters = {
        situacao: ['Em andamento', 'Concluída'],
        ordemDescricaoCampanha : true
      }
      this.$http.get(this.$api.campanhaEmpresaParameter(idCompany), {params: parameters}).then(({data}) => {
        const campaignsOptions = []
        data.forEach(campaign => {
          
          const alreadyExists = campaignsOptions.some(option => option.id === campaign.id_campanha); 
          
          if(!alreadyExists) {
            campaignsOptions.push({
              id: campaign.id_campanha,
              descricao: campaign.descricao_campanha, 
              doses_previstas: campaign.total_doses - campaign.doses_aplicadas,
              doses_aplicadas: campaign.doses_aplicadas,
              situacao: campaign.situacao
            })
          }
        
        })
        this.campaignsOptions = campaignsOptions
      })
    },
    setAddress(addresses) {
      const addressesOptions = [];
      if(addresses.length > 1) {
        addresses.forEach(address => {
          if((address.id_cidade && address.id_empresa_endereco)) {
            if(!(address.logradouro == null || address.bairro == null || address.cep == null)) {
              addressesOptions.push({
                id_cidade: address.id_cidade,
                id_empresa_endereco: address.id_empresa_endereco,
                descricao: this.formatAddress(address),
                descricao_identificador: address.descricao
              }) 
            }
          } 
        })    
      } else {
        this.form.endereco = {
          id_cidade : addresses[0].id_cidade,
          id_empresa_endereco : addresses[0].id_empresa_endereco,
          descricao : `${addresses[0].logradouro}, ${addresses[0].numero ? addresses[0].numero : 'Sem número'}, ${addresses[0].bairro}, ${addresses[0].cidade.nome}, ${addresses[0].cidade.uf}, ${addresses[0].cep}`,
          descricao_identificador: addresses[0].descricao
        }

        addressesOptions.push({
          id_cidade : addresses[0].id_cidade,
          id_empresa_endereco : addresses[0].id_empresa_endereco,
          descricao : `${addresses[0].logradouro}, ${addresses[0].numero ? addresses[0].numero : 'Sem número'}, ${addresses[0].bairro}, ${addresses[0].cidade.nome}, ${addresses[0].cidade.uf}, ${addresses[0].cep}`,
          descricao_identificador : addresses[0].descricao
        }) 
        this.getUnits()
      }
      this.enderecoOptions = addressesOptions

      this.enderecoOptions.push({
        descricao : 'Cadastrar endereço para esse agendamento',
        descricao_identificador : 'Outro'
      })
    },
    addNewAddress(data) {
      this.modalNewAddress.showModal = false

      const existingIndex = this.enderecoOptions.findIndex(
        (endereco) => endereco.descricao_identificador === 'Novo endereço'
      );

      const newAddress = {
        descricao_identificador: 'Novo endereço',
        descricao: this.formatAddress(data),
        id_cidade: data.cidade.id_cidade,
        ponto_referencia: data.ponto_referencia,
        cep: data.cep,
        logradouro: data.logradouro,
        numero: data.numero,
        bairro: data.bairro,
        cidade: data.cidade,
        uf: data.cidade.uf,
      };

      if (existingIndex !== -1) {
        this.$set(this.enderecoOptions, existingIndex, newAddress);
      } else {
        this.enderecoOptions.push(newAddress);
        this.enderecoOptions = this.ordenarEnderecos(this.enderecoOptions);
      }

      this.form.endereco = newAddress;
      this.getUnitsByIdCidade();
    },
    ordenarEnderecos(enderecos) {
      return [...enderecos].sort((a, b) => {
        if (a.descricao_identificador === 'Outro') return 1;
        if (b.descricao_identificador === 'Outro') return -1;
        return 0;
      });
    },
    formatAddress(address) {
      let logradouro = address.logradouro ? address.logradouro : ''
      let numero = address.numero ? address.numero : 'Sem número'
      let bairro = address.bairro ? address.bairro : ''
      let nomeCidade = address.cidade.nome ? address.cidade.nome : ''
      let uf = address.cidade.uf ? address.cidade.uf : ''
      let cep = address.cep ? address.cep : ''

      return `${logradouro} , ${numero}, ${bairro}, ${nomeCidade}, ${uf}, ${cep}`
    },
    async validateForm() {
      const formValidated =  await this.$refs.agendamentoForm.validate();
      return formValidated;
    },
    payload() {
      const novoEndereco = this.enderecoOptions.find(
        (endereco) => endereco.descricao_identificador === 'Novo endereço'
      );

      return {
        id_empresa : this.id_empresa,
        id_unidade : this.form.unidade_operacional.id,
        id_empresa_endereco: this.form.endereco.id_empresa_endereco,
        id_campanha: this.form.campaign.id,
        data_aplicacao : moment(this.form.data_aplicacao, "DD/MM/YYYY").format("YYYY-MM-DD"),
        data_final_aplicacao: moment(this.form.data_final_aplicacao, "DD/MM/YYYY").format("YYYY-MM-DD"),
        periodo : this.form.periodo,
        horario_inicio: this.form.horario_inicio,
        horario_termino: this.form.horario_termino,
        quantidade_dose_prevista: this.form.doses_previstas.toString().replaceAll('.',''),
        quantidade_dose_aplicada : this.form.doses_aplicadas.toString().replaceAll('.',''),
        situacao: this.form.status,
        observacao: this.form.observacoes,
        cep: novoEndereco?.cep || '',
        logradouro: novoEndereco?.logradouro || '',
        numero: novoEndereco?.numero || '',
        bairro: novoEndereco?.bairro || '',
        complemento: novoEndereco?.ponto_referencia || '',
        id_cidade: novoEndereco?.cidade?.id_cidade || null,
        destinatarios: this.emailsSelecteds
      }
    },
    openModalSuccess() {
      this.modalSuccess.showModal = true;
    },
    openModalNewAddress() {
      this.modalNewAddress.showModal = true
    },
    closeModalNewAddress() {
      this.modalNewAddress.showModal = false;
      this.form.endereco = this.enderecoOptions.find(
        (endereco) => endereco.descricao_identificador === 'Novo endereço'
      ) || this.enderecoOptions[0];
    },
    redirectToList() {
      this.$router.push({ name: 'agendamento-list' });
    },
    closeModal() {
      this.modalSuccess.showModal = false;
    },
    openModalCancel() {
      handleErrors.cleanErrors(this.form.errorApi)
      this.modalCancel.showModal = true
    },
    closeModalCancel() {
      this.modalCancel.showModal = false
    },
    handleError(response) {
      const errors = response.status === 400 || response.status === 404

      if (errors) {
        confirmOkActionTriangleWarn(
          response.data.errors,
          'Confira os dados e depois salve o agendamento.',
          'Entendido',
        )

        return
      }

      confirmOkActionTriangleWarn(
        'Não foi possível realizar a sua solicitação.<br /> Entre em contato com o suporte.',
      )
    },
    openModalErrorSave() {
      this.$swal({
          title: 'Ocorreu um erro.',
          icon: 'warning',
          iconColor: "red",
          html: '<div>Ocorreu um erro ao tentar salvar o agendamento.</div>',
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: "btn btn-custom-blue"
          },
          buttonsStyling: false
      })
    },
    setDosesPrevistas() {
      const params = {
        id_empresa : this.id_empresa,
        id_unidade : this.form.unidade_operacional.id,
        id_campanha: this.form.campaign.id,
        id_empresa_endereco : this.form.endereco.id_empresa_endereco
      }

      this.$http.get(this.$api.campanhaEmpresaResumoDoseAplicada(), {params}).then(({ data }) => {
          if(params.id_empresa_endereco) {
            this.form.doses_previstas = data.quantidade_doses_restante
          }
          this.form.doses_aplicadas = data.quantidade_vacinados
      })
    },
    hourChangeSetPeriod(newVal) { 
      if (!newVal) return;

      if (newVal && this.form.horario_inicio.length === 5) {
        this.form.horario_inicio = `${newVal}:00`; 
      }else if (newVal && this.form.horario_termino.length === 5) {
        this.form.horario_termino = `${newVal}:00`;
      }

      if(this.form.horario_inicio && this.form.horario_termino) {
        const periodService = PeriodAgendamentoService.handlePeriod(this.form.horario_inicio, this.form.horario_termino)

        handleErrors.cleanErrors(this.form.errorApi)

        if(periodService.msg) {
          handleErrors.handleErrorsHours(periodService.msg, this.form.errorApi)
        } 

        this.form.periodo = periodService.period
        this.periodoOptions = periodService.periodOptions
      }
    },
    verifyPermission() {
      return this.$can(actions.ATUALIZAR, subjects.AGENDAMENTO_SESI) || this.$can(actions.ATUALIZAR, subjects.AGENDAMENTO_SESI_UO);
    },
    clearFields() {
      this.form.nome_fantasia = ""
      this.form.razao_social = ""
      this.form.endereco = ""
      this.id_empresa = ""
      this.unidadeOperacionalOptions = []
      this.form.unidade_operacional = ""
      this.enderecoOptions = []
      this.campaignsOptions = []
      this.form.campaign = ""
      this.form.doses_aplicadas = ""
      this.form.doses_previstas = ""
    },
    confirmSelectEmails(recipients) {
      this.emailsSelecteds = recipients.map(recipient => recipient.email);
      this.modalSelectEmailsConfirmed = true;
      this.showModalSelectEmails = false;
      this.saveAgendamento();
    },
    closeModalSelectEmails() {
      this.showModalSelectEmails = false
      this.loading = false;
    },
  },
  watch: {
    'form.horario_inicio': 'hourChangeSetPeriod',
    'form.horario_termino': 'hourChangeSetPeriod',
    'form.unidade_operacional': function (newValue) {
        this.campanhaDisabled = newValue ? false : true
    },
    data_aplicacao(newVal) {
      if (moment(newVal, "YYYY-MM-DD", true).isValid()) {
        this.form.data_aplicacao = moment(newVal).format("DD/MM/YYYY");
      }
    },
    "form.data_aplicacao"(newVal) {
      if (moment(newVal, "DD/MM/YYYY", true).isValid()) {
        this.data_aplicacao = moment(newVal, "DD/MM/YYYY").format("YYYY-MM-DD");
      }

      if(moment(newVal, "DD/MM/YYYY", true).isValid() && !this.form.data_final_aplicacao){
        this.$nextTick(() => {
          this.$refs.dataTermino.focus();
        });
      }
    },
    data_final_aplicacao(newVal) {
      if (moment(newVal, "YYYY-MM-DD", true).isValid()) {
        this.form.data_final_aplicacao = moment(newVal).format("DD/MM/YYYY");
      }
    },
    "form.data_final_aplicacao"(newVal) {
      if (moment(newVal, "DD/MM/YYYY", true).isValid()) {
        this.data_final_aplicacao = moment(newVal, "DD/MM/YYYY").format("YYYY-MM-DD");
      }

      if(moment(newVal, "DD/MM/YYYY", true).isValid() && !this.form.data_aplicacao){
        this.$nextTick(() => {
          this.$refs.dataAplicacao.focus();
        });        
      }
    },
  }
}
</script>  
<style>
.custom-datepicker-menu {
  transform: translateX(-175px) translateY(40px) !important;
}
</style>